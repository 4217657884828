import React from 'react'
import Layout from '../layouts'
import './index.css'
import CommonHero from '../components/commonhero/commonhero.js'

const AboutColoradoPage = () => (
  <Layout head={{ title: 'About Colorado Springs' }}>
    <CommonHero
      pageTitle="About Colorado Springs"
      custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1554967665/brianboals/aboutcs.jpg"
    />
    <main className="bg-white">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12 mb-5">
            <h2 className="mb-4">Area History</h2>
            <p>
              When Colorado Springs was established there were over 100
              millionaires in this area due to the Pikes Peak Gold Rush. Many
              built homes along the impressive Wood Avenue in downtown Colorado
              Springs, which is still an impressive drive today.
            </p>
            <p>
              General William Jackson Palmer had big dreams for Colorado
              Springs, envisioning a cultural hot spot befitting his high-class
              wife. He was responsible for bringing electricity and the train to
              the. Upon one of his first visits, he wrote to his wife, “Could
              one live in constant view of these grand mountains without being
              elevated by them into a lofty place of thought and purpose?” This
              mindset no doubt encouraged him to establish a city regulation to
              keep buildings low so they would not obstruct the views of the
              mountains.
            </p>
          </div>
        </div>

        <h3 className="mb-4">Restaurant List by Area</h3>
        <div className="row flex-wrap">
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1559282130/brianboals/westbg.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Southwest:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Broadmoor</li>
                  <li>Edelweiss</li>
                  <li>Prime</li>
                  <li>Ivywild</li>
                  <li>Cheyenne Mountain Resort</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1559280321/brianboals/downtownbg.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Downtown Colorado Springs:
                </h4>
                <div className="row">
                  <div className="col-md-6 pr-0">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>Fujiama</li>
                      <li>Denver Biscuit Company</li>
                      <li>Coquettes</li>
                      <li>Paris Crepes</li>
                    </ul>
                  </div>
                  <div className="col-md-6 pr-0">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>The Famous</li>
                      <li>The Skirted Heifer</li>
                      <li>McKenzies</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572848228/brianboals/oldColoradoCity.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Old Colorado City:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Rudy’s</li>
                  <li>Jake and Telly’s</li>
                  <li>Pizzeria Rustica</li>
                  <li>Paravicini’s</li>
                  <li>La Baguette</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1559281532/brianboals/manitoubg.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Manitou Springs:
                </h4>

                <div className="row">
                  <div className="col-md-6 pr-0">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>Mona Lisa</li>
                      <li>Cliff House</li>
                      <li>The Loop</li>
                      <li>Adams Mountain Cafe</li>
                      <li>Sahara Cafe</li>
                    </ul>
                  </div>
                  <div className="col-md-6 pr-0">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>Briarhurst Manor</li>
                      <li>Swirl</li>
                      <li>Crystal Park Cantina</li>
                      <li>PJ's Stagecoach Inn</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572848462/brianboals/west.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">West:</h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Black Bear Diner</li>
                  <li>Marigolds</li>
                  <li>Little Nepal</li>
                  <li>Caspian Cafe</li>
                  <li>Mollica's Italian Deli</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1559284025/brianboals/coloradospringsbg.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Northwest:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Oliver’s Deli</li>
                  <li>Margarita at Pine Creek</li>
                  <li>Mama Pearls Cajun Diner</li>
                  <li>Bird Dog BBQ</li>
                  <li>Narai Thai</li>
                  <li>Salsa Brava Fresh Mexican Grill</li>
                  <li>Cowboy Star</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1559282321/brianboals/powersbg.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">Powers:</h4>
                <div className="row">
                  <div className="col-md-6 pr-0">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>Tucano’s</li>
                      <li>Till</li>
                      <li>Jose Muldoons</li>
                      <li>Piglatin Cocina</li>
                      <li>Happy Time Dine In</li>
                    </ul>
                  </div>
                  <div className="col-md-6 pr-0">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>The Wobbly Olive</li>
                      <li>Urban Egg</li>
                      <li>Pueblo Viejo</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572849551/brianboals/northGate.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Northgate:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Bourbon Brothers</li>
                  <li>The Rocky Mountain Brewery</li>
                  <li>Sprigs</li>
                  <li>Duca's Neapolitan Pizza</li>
                  <li>CB & Potts</li>
                  <li>Fuzzy's Taco Shop</li>
                  <li>Sushi Ato</li>
                  <li>Atmosphere Gastropub</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572849649/brianboals/monument.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">Monument:</h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>La Casa Fiesta</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572849811/brianboals/shopping.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Shopping Destinations:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Prominade at Briargate</li>
                  <li>Chapel Hills Mall</li>
                  <li>City Center</li>
                  <li>University Village</li>
                  <li>Old Colorado City</li>
                  <li>Downtown Colorado Springs</li>
                  <li>Downtown Monument</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h3 className="mt-5 mb-4">Colorado Springs Area Attractions</h3>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1554967665/brianboals/aboutcs.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Colorado Springs:
                </h4>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>Garden of the Gods</li>
                      <li>Horse Back Riding</li>
                      <li>Hiking</li>
                      <li>Gift Shops</li>
                      <li>CC Hockey Games</li>
                      <li>Broadmoor Resort</li>
                      <li>7 Falls</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled m-0 rla-list-area">
                      <li>Helen Hunt Falls</li>
                      <li>Cheyenne Mountain Zoo</li>
                      <li>Cheyenne Canyon</li>
                      <li>Farmers Market</li>
                      <li>Old Colorado City</li>
                      <li>America The Beautiful Park</li>
                      <li>Chapel Hills Mall</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572850099/brianboals/manitouSpring.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Manitou Springs:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Fruit Cake Toss</li>
                  <li>Emma Crawford Coffin Race</li>
                  <li>Clay Fest</li>
                  <li>The Incline</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1559283697/brianboals/airforcebg.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Air Force Academy:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Football Games</li>
                  <li>Hockey Games</li>
                  <li>Basketball Games</li>
                  <li>Trails</li>
                  <li>Aeronautical Exhibits</li>
                  <li>AFA Chapel</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572850252/brianboals/arts.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">Arts:</h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Pikes Peak Center</li>
                  <li>Fine Art Center</li>
                  <li>Ent Performance Center</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <div className="rla bg-white">
              <div
                className="rla-image"
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1572850403/brianboals/dogParks.jpg)`,
                }}
              />
              <div className="rla-content">
                <h4 className="text-white font-weight-bold mb-3">
                  Dog Parks:
                </h4>
                <ul className="list-unstyled m-0 rla-list-area">
                  <li>Bear Creek</li>
                  <li>Briargate</li>
                  <li>Palmer Park</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default AboutColoradoPage
